<template>
    <b-modal
      id="editEntry"
      :title="datos.name +' / '+ datos.group + ' / ' + datos.element"
      ok-title="Guardar Cambios"
      cancel-variant="outline-secondary"
      cancel-title="Cancelar"
      @ok="confirmEdit"
      class="capitalize"
      @close="cancelEdit"
      @cancel="cancelEdit"
      @hide="cancelEdit"
    >
      <b-form>
          <b-form-group
          label="Valor Actual"
          label-for="curretValue"
        >
          <b-form-input
            id="curretValue"
            placeholder="Valor Actual"
            v-model="datos.val"
            :disabled="true"
          />
        </b-form-group> 
        <b-form-group
          label="Ingresar nuevo valor"
          label-for="dataValue"
        >
          <b-form-input
            id="dataValue"
            placeholder="Valor"
            v-model="newValue"
            type="number"
          />
        </b-form-group>
        <v-divider></v-divider>
        <b-form-group
          label="Hora de toma actual"
          label-for="dataValue"
        >
          <b-form-input
            id="dataValue"
            :placeholder="HoraT(datos.FechaRelativa)"    
            :disabled="true"            
          />
        </b-form-group>
        <b-form-group
        label="Ingresa nueva hora de toma">
          <template>
            <div data-app>            
              <v-menu
                ref="menu"
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="horaReal"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >            
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="horaReal"                  
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    class="form-control mt-0"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="menu2"
                  v-model="horaReal"
                  full-width
                  format="24hr"
                  @click:minute="$refs.menu.save(horaReal)"
                ></v-time-picker>
              </v-menu>
            </div>
          </template>
        </b-form-group>                    
      </b-form>
    </b-modal>
</template>
<script>
import {
  BButton, BModal, VBModal, BForm, BFormInput, BFormGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import laboratorio from '@/../apis/Laboratorio'
export default {
    name: 'PopUpEdit',
    components: {
        BButton,
        BModal,
        BForm,
        BFormInput,
        BFormGroup,
    },
    directives: {
        'b-modal': VBModal,
        Ripple,
    },
    methods:{
      cancelEdit(){
        this.newValue=null            
        this.horaReal=null
      },          
      confirmEdit(){
        if(this.newValue >= 0 && this.horaReal){
          this.datos.new = Number(this.newValue)            
          const actualDate = new Date(this.datos.FechaRelativa)
          const newDate = this.horaReal.split(':')              
          if(newDate.length >= 2){
            actualDate.setUTCHours(newDate[0])
            actualDate.setUTCMinutes(newDate[1])
          }
          this.datos.newDate = actualDate.getTime() / 1000
          console.log(this.datos)          
          laboratorio.actions.actualizarRegistro(this.datos).then((response)=>{
            this.$emit('success', true)
          }).catch((error)=>{
            this.$emit('success', false)                    
          })
          }
        },
        HoraT(item){
          const date = new Date(item)
          const hora = ("0" + date.getUTCHours()).slice(-2) 
          const minutos = ("0" + date.getUTCMinutes()).slice(-2)           
          return hora + ':' + minutos 
        },
    },
    data(){
      return{
        newValue:null,            
        horaReal: null,
        menu2:false, 
      }
    },
    props:{
      datos:{
        required: true
      }
    },
    watch:{
      datos: function (){
        this.newValue = this.datos.val        
        this.horaReal = this.HoraT(this.datos.FechaRelativa)
      }
    }
}
</script>
<style>
#editEntry___BV_modal_title_{
    text-transform: capitalize;
}
</style>
<style lang="scss">
$primary: #FE6F17;
.theme--light.v-time-picker-clock--indeterminate .v-time-picker-clock__hand,
.primary,
.theme--light.v-time-picker-clock--indeterminate .v-time-picker-clock__item--active,
.v-time-picker-clock__hand,.v-time-picker-clock__item--active{
  background: $primary;
}
.v-time-picker-clock__hand::before{
  border-color: $primary;
}
.dark-layout .v-time-picker-clock{
  background: #283046;
}
.dark-layout .v-time-picker-clock__inner{
  color: #b4b7bd;
}
.dark-layout .v-time-picker-clock__container{
  background: #161D31;
}
.v-picker--time{
  border: none;
}
.v-input__prepend-outer,
.theme--light.v-text-field>.v-input__control>.v-input__slot:before,
.v-text-field>.v-input__control>.v-input__slot:after{
  display: none;
}
.v-text-field__slot input{
  padding: 0;
  padding-top: 2px;
}
.dark-layout .v-input.form-control{
  border-color:#404656;
  color: #b4b7bd;
  background: transparent;
}
.dark-layout .v-text-field__slot input{
  color: #b4b7bd;
}
.v-input input{
  color:#6e6b7b!important;
}
.dark-layout .v-divider {
    border-color: hsla(0,0%,100%,.12);
}
</style>