import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    enviarRegistro(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post('/lab/insert', queryParams)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    obtenerRegistros(queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/lab/regs', queryParams)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    filtrarRegistros(queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/lab/regsFilter', {
            params: queryParams,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    actualizarRegistro(queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post('/lab/update', queryParams)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    eliminarRegistro(queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post('/lab/registro', queryParams)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    datosLinea(queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/envasado', { params: queryParams })
          .then(response => resolve(response?.data))
          .catch(error => resolve([]))
      })
    },
    obtenerUsuarios(queryParams) {
      return new Promise((resolver, reject) => {
        axios
          .get('/users', queryParams)
          .then(response => resolver(response))
          .catch(error => reject(error))
      })
    },
  },
}
