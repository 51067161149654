<!-- 28 de Diciembre 2021 - Cambios necesarios debido a los usuarios, se removio la hora programada debido a que era confuso -->
<template>    
    <b-card>
        <b-row>            
            <b-col md="2">
                <b-form-group>        
                    <h5>Laboratorio</h5>    
                    <b-form-select
                    v-model="labSelected"
                    :options="laboratorios"
                    />            
                </b-form-group>
            </b-col>
            <b-col md="2">
                <b-form-group>        
                    <h5>Tipo de Muestra</h5>    
                    <b-form-select
                    v-model="pageSelected"
                    :options="pagesList"
                    />            
                </b-form-group>
            </b-col>            
            <b-col md="2" v-if="singleData && singleData.groups">
                <b-form-group>
                    <h5>Grupo</h5>
                    <v-select
                    v-model="group"
                    label="Grupo"
                    :options="singleData.groups"            
                    />
                </b-form-group>
            </b-col>
            <b-col md="4">
                <h5>Fecha</h5>  
                <flat-pickr
                    v-model="date"
                    class="form-control"
                    :config="{ dateFormat: 'Y-m-d', mode:'range'}"
                />            
            </b-col>            
            <b-col md="2">
                <b-form-group>
                    <b-button
                        class="mr-auto mt-2 w-full"
                        variant="gradient-primary"
                        @click="$emit('filter', {date: dateFr, muestra: pageSelected, hour: horaRelativa, lab: labSelected, group: group, dateTo: dateTo})"            
                    >
                        Filtrar
                    </b-button>
                </b-form-group>
            </b-col>
        </b-row>        
    </b-card>
</template>

<script>
import {BCard, BRow, BFormGroup, BCol, BFormSelect, BButton} from 'bootstrap-vue'
import Ripple from "vue-ripple-directive"
import flatPickr from 'vue-flatpickr-component'
import vSelect from "vue-select"
import firebase from 'firebase/app'
import 'firebase/database'

export default {
    data (){
        return{
            date:null,
            dateFr: null,
            dateTo: null,
            pageSelected:null,
            pagesList: [],
            horaRelativa: null,
            optionsTime: [],
            laboratorios: [],
            labSelected: null,
            group: null,
            singleData: null
        }
    },
    directives: {
        Ripple,
    },  
    components:{
        BCard, BRow, flatPickr, BFormGroup, BCol, BFormSelect, vSelect, BButton
    },
    methods:{
        setToday() {
            const now = new Date();
            this.date = now.getFullYear() +'-' + (Number(now.getMonth()) + 1) + '-' + ("0" + now.getDate()).slice(-2)  
        },
        renderOptions(){
            this.pagesList = []
            Object.entries(this.muestras).forEach((element, key)=>{                
                const oj = {value: element[0], text: element[1].name}                        
                if(element[1].lab == this.labSelected) this.pagesList.push(oj)
            })
        },
    },
    watch:{
        labSelected: function (){
            this.renderOptions()
            this.pageSelected = this.pagesList[0].value               
        },
        pageSelected: function (){
            this.singleData = this.muestras[this.pageSelected]
            this.optionsTime = this.singleData.times
        },
        date: function (){            
            const arrDate = this.date.split(' ')
            const fr = new Date(arrDate[0])
            const to = new Date(arrDate[2])
            this.dateFr  = fr.getTime() / 1000
            this.dateTo = to.getTime() / 1000    
        }
    },
    created(){
        // firebase.database().ref('muestreo').on('value', (snap)=>{
        //     this.muestras = snap.val()      
        //     this.renderOptions()      
        //     this.pageSelected = this.pagesList[0].value              
        //     this.singleData = this.muestras[this.pageSelected];
        //     this.optionsTime = this.singleData.times
        //     // this.renderObjectSelected()
        //     this.setToday()
        // })
        firebase.database().ref('laboratorios').once('value', (snap)=>{
            this.laboratorios = snap.val()    
                firebase.database().ref('muestreo').once('value', (snap)=>{
                this.muestras = snap.val()                  
                this.labSelected = this.laboratorios[0] 
                this.renderOptions()  
                this.pageSelected = this.pagesList[0].value
                this.singleData = this.muestras[this.pageSelected]
                this.optionsTime = this.singleData.times                                
                this.setToday()
            })  
        })
    }
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>