<template>
    <div>
        <filters @filter="filtrar" />
        <history :items="items" @delete="deleteItem" @edit="editItem" :totalRows="items.length" />
        <editModal :datos="popUpData" @success="editConfirm"/> 
    </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import laboratorio from '@/../apis/Laboratorio'
import Filters from './Filters.vue'
import History from './History.vue'
import editModal from './EditPopUp.vue'
export default {
    name:'RegistrosLab',
    components:{
        Filters,
        History,
        editModal
    },
    data(){
        return{
            items: [],
            tempSave: null,
            popUpData:{}
        }
    },
    mounted(){
        this.loadData()
    },
    methods:{
        loadData(){
            laboratorio.actions.obtenerRegistros().then((value)=>{
                this.items = value.data.data
            })
        },
        filtrar(event){
            this.tempSave = event
            laboratorio.actions.filtrarRegistros(event).then((value)=>{
                this.items = value.data.results
            })            
        },
        deleteItem(event){
            this.$bvModal
            .msgBoxConfirm('Por favor confirmar que desea eliminar el dato.', {
                title: 'Confirmar',
                size: 'sm',
                okVariant: 'primary',
                okTitle: 'Confirmar',
                cancelTitle: 'Cancelar',
                cancelVariant: 'outline-secondary',
                hideHeaderClose: false,
                centered: true,
            })
            .then(value => {
                if(value){
                    const obj = {id:event}
                    laboratorio.actions.eliminarRegistro(obj).then((response)=>{
                        if(response.status == 200){
                            this.filtrar(this.tempSave)
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                title: 'Dato Eliminado.',                  
                                icon: 'TrashIcon',
                                variant: 'danger',
                                },
                            })
                        }
                    })
                }
            })
        },
        editItem(event){
            this.popUpData = event
            if(this.popUpData) this.$bvModal.show('editEntry')
        },
        editConfirm(event){
            if(event){
                this.filtrar(this.tempSave)
                this.$toast({
                component: ToastificationContent,
                    props: {
                        title: 'Cambios Guardados.',                  
                        icon: 'SaveIcon',
                        variant: 'success',
                    },
                })
            }else{
                this.$toast({
                component: ToastificationContent,
                    props: {
                        title: 'Cambios Guardados.',                  
                        icon: 'AlerttriangleIcon',
                        variant: 'success',
                    },
                })
            }
        }
    }
}
</script>